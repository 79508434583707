/* eslint-disable no-nested-ternary */
import React, { forwardRef } from 'react'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'
import { lighten } from 'polished'
import { PhoneButton, RegisterButton } from '../../UI'

const NAME = 'Header'

const style = {
    section: tw`relative overflow-hidden h-full`,
    wrapper: tw`max-w-7xl mb-0 mx-auto px-0 md:px-6 lg:px-8 `,
    bigTitle: tw`antialiased leading-tight tracking-tighter font-bold items-start mb-2 text-4xl`,
    smallTitle: tw`text-2xl sm:text-4xl`,
    announcer: tw`text-base font-medium mb-2 text-tellow-purple`,
    text: tw`antialiased text-sm max-w-prose font-normal mb-10 opacity-80`,
    phoneNumber: tw`hover:opacity-100 ml-1`,
    content: tw`flex flex-col h-auto items-start lg:pt-10 px-4 md:px-0`,
    img: tw`md:inline mx-auto mt-5 -mb-2 p-0`,
    imageContainer: tw`flex flex-col justify-center`,
    imageContainerBottom: tw`flex flex-col justify-end px-3 md:px-0 mt-auto -mb-20`,
    lessBottomMarginOnMobile: tw`mb-0 md:-mb-10 lg:-mb-20`,
    mockup: tw`absolute h-1/5 left-0 right-0 bottom-0 z-10`,
    outlined: tw`bg-white! border-2 border-solid border-tellow-purple! text-tellow-purple whitespace-pre-wrap break-normal items-center justify-center text-center`,
    textWhite: tw`text-tellow-white`,
    textGreen: tw`text-tellow-green`,
    textPurple: tw`text-tellow-purple`,
    points: tw`mb-8`,
    point: tw`mb-2`,
    priceDisclaimerWhite: tw`opacity-100 mt-3`,
}

const Container = styled.div`
    display: flex;
    position: relative;
    z-index: 20;
    max-width: 1208px;
    margin: 3rem auto 5rem auto;

    @media screen and (max-width: 1024px) {
        margin-top: 1.5rem;
        margin-bottom: 3rem;
    }

    @media (max-width: 768px) {
        margin-bottom: ${(props) => (props.lessBottomMarginOnMobile ? 0 : '3rem')};
    }
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: ${(props) => (props.columnSize ? `1fr 2fr` : `repeat(2, minmax(0, 1fr))`)};
    gap: 2.5rem;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`
const ButtonContainer = styled.div`
    display: grid;
    grid-template-columns: ${(props) => (props.buttonContainerGrid ? `repeat(2, minmax(0, 1fr))` : `auto`)};
    gap: 10px;

    .phone-button {
        order: ${(props) => (props.reverseButtonGrid ? 1 : -1)};
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 10px;
    }
`
const AnimationContainer = styled.div`
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Section = styled.div`
    position: relative;
    overflow: hidden;
    height: 100%;
    background-color: ${(props) =>
        props.disabled
            ? props.theme.color.tellowGray200
            : props.primary
            ? props.theme.color.tellowWhite
            : props.tertiary
            ? props.theme.color.tellowBlue
            : props.grey
            ? props.theme.color.tellowGray500
            : props.purple
            ? props.theme.color.tellowPurple
            : props.red
            ? props.theme.color.tellowRed
            : props.gray
            ? props.theme.color.tellowGray800
            : props.green
            ? props.theme.color.tellowGreen
            : props.lightPurple
            ? props.theme.color.tellowPurpleLight
            : props.theme.color.tellowPurple};

    ${({ gradient, theme }) =>
        gradient
            ? css`
                  background-image: radial-gradient(circle at top right, ${theme.color.tellowPurple} 0%, transparent 25%),
                      linear-gradient(175deg, ${theme.color.tellowPurple} 20%, transparent 55%),
                      linear-gradient(to right, ${theme.color.tellowPurple}, ${lighten(0.1, theme.color.tellowPurple)}) !important;
              `
            : css`
                  background-color: ${theme.color.tellowWhite};
              `};
`

// Remove purpleBackround ? if you want to only use the props
const Header = forwardRef(
    (
        {
            data,
            image,
            bottomImage,
            purpleBackground,
            greenAnnouncer = false,
            columnSize,
            showArrowInLink = true,
            showPhoneButton = false,
            showPhoneButtonPrimary = false,
            phoneButtonWhiteBorder = false,
            reverseButtonGrid = false,
            buttonContainerGrid,
            registerButtonSecondary = false,
            showAnimation = false,
            displayImage = true,
            smallerTitleSizeOnMobile = false,
            priceDisclaimerWhite = false,
            lessBottomMarginOnMobile = false,
            ...props
        },
        ref
    ) => (
        <Section {...props} ref={ref} gradient={purpleBackground}>
            <Container lessBottomMarginOnMobile={lessBottomMarginOnMobile}>
                <div css={style.wrapper}>
                    <Grid columnSize={columnSize}>
                        <div css={style.content}>
                            <p css={[style.announcer, greenAnnouncer ? style.textGreen : purpleBackground ? style.textWhite : null]}>{data.announcer}</p>
                            <h1
                                css={[
                                    style.bigTitle,
                                    purpleBackground ? style.textWhite : null,
                                    data.text === undefined && tw`mb-8!`,
                                    smallerTitleSizeOnMobile ? style.smallTitle : null,
                                ]}
                            >
                                {data.title}
                            </h1>
                            {data.text && <p css={[style.text, purpleBackground ? style.textWhite : null]}>{data.text}</p>}
                            {data.points && (
                                <ul css={[style.text, style.points, purpleBackground ? style.textWhite : null]}>
                                    {data.points.map((point) => (
                                        <li key={point} css={style.point}>
                                            {point}
                                        </li>
                                    ))}
                                </ul>
                            )}

                            {/* If the buttonContainerGrid = true it uses the inline styling (to render two buttons in a grid),
                             If buttonContainerGrid = false it uses the default styling of the component <ButtonContainer/> (to render one button in own size) */}

                            <ButtonContainer buttonContainerGrid={buttonContainerGrid} reverseButtonGrid={reverseButtonGrid}>
                                {(showPhoneButton || showPhoneButtonPrimary) && (
                                    <PhoneButton
                                        big
                                        purple={showPhoneButtonPrimary}
                                        style={{ padding: '18px 15px' }}
                                        className="phone-button"
                                        border={
                                            phoneButtonWhiteBorder
                                                ? {
                                                      border: 'white',
                                                      borderWidth: '2',
                                                      borderColor: '#FFF',
                                                      borderRadius: '0.75',
                                                      whiteTransparent: true,
                                                  }
                                                : undefined
                                        }
                                    />
                                )}

                                <RegisterButton
                                    big
                                    lang={data.lang}
                                    url={data.url}
                                    tier={data.tier}
                                    campaign={data.campaign}
                                    style={{ textAlign: 'center' }}
                                    css={[registerButtonSecondary ? style.outlined : undefined, purpleBackground ? style.textPurple : undefined]}
                                    primary={purpleBackground}
                                >
                                    {data.textCTA}
                                    {showArrowInLink && <span className="arrow">{'->'}</span>}
                                </RegisterButton>
                            </ButtonContainer>

                            {(data.information || data.phone) && (
                                <p css={[style.text, purpleBackground ? style.textWhite : null, priceDisclaimerWhite ? style.priceDisclaimerWhite : tw`mt-6`]}>
                                    {data.information}
                                    <a css={[style.text, style.phoneNumber, purpleBackground ? style.textWhite : null]} href={`tel:${data.phone}`}>
                                        {data.phone}
                                    </a>
                                </p>
                            )}
                        </div>
                        <div css={[bottomImage ? style.imageContainerBottom : style.imageContainer, lessBottomMarginOnMobile ? style.lessBottomMarginOnMobile : null]}>
                            {displayImage && <img css={style.img} src={data?.headerImage || image} alt="Header afbeelding" />}
                            {showAnimation && <AnimationContainer>{data.animation}</AnimationContainer>}
                        </div>
                    </Grid>
                </div>
            </Container>
        </Section>
    )
)

Header.displayName = NAME

export default Header
