import React from 'react'
import tw from 'twin.macro'

const Credit = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple`} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.6162 7.06012C6.68769 7.06012 5.7972 7.42897 5.14064 8.08553C4.48409 8.74209 4.11523 9.63257 4.11523 10.5611V12.3116H32.123V10.5611C32.123 9.63257 31.7541 8.74209 31.0976 8.08553C30.441 7.42897 29.5505 7.06012 28.622 7.06012H7.6162Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.123 15.8126H4.11523V24.565C4.11523 25.4935 4.48409 26.384 5.14064 27.0405C5.7972 27.6971 6.68769 28.066 7.6162 28.066H28.622C29.5505 28.066 30.441 27.6971 31.0976 27.0405C31.7541 26.384 32.123 25.4935 32.123 24.565V15.8126ZM7.6162 22.8145C7.6162 22.3502 7.80063 21.905 8.12891 21.5767C8.45719 21.2484 8.90243 21.064 9.36669 21.064H11.1172C11.5814 21.064 12.0267 21.2484 12.355 21.5767C12.6832 21.905 12.8677 22.3502 12.8677 22.8145C12.8677 23.2788 12.6832 23.724 12.355 24.0523C12.0267 24.3806 11.5814 24.565 11.1172 24.565H9.36669C8.90243 24.565 8.45719 24.3806 8.12891 24.0523C7.80063 23.724 7.6162 23.2788 7.6162 22.8145ZM16.3686 21.064C15.9044 21.064 15.4591 21.2484 15.1308 21.5767C14.8026 21.905 14.6181 22.3502 14.6181 22.8145C14.6181 23.2788 14.8026 23.724 15.1308 24.0523C15.4591 24.3806 15.9044 24.565 16.3686 24.565H18.1191C18.5834 24.565 19.0286 24.3806 19.3569 24.0523C19.6852 23.724 19.8696 23.2788 19.8696 22.8145C19.8696 22.3502 19.6852 21.905 19.3569 21.5767C19.0286 21.2484 18.5834 21.064 18.1191 21.064H16.3686Z"
            fill="currentColor"
        />
    </svg>
)

export default Credit
