import React from 'react'
import tw, { styled } from 'twin.macro'

const style = {
    wrapper: tw`max-w-7xl my-8 mx-auto px-4 sm:px-6 lg:px-8`,
    grid: tw`grid grid-cols-1 md:grid-cols-2 gap-6`,
    pointsGrid: tw`grid grid-flow-row auto-rows-max max-w-md gap-6`,
    announcer: tw`text-base font-semibold mb-2 text-tellow-purple`,
    bigTitle: tw`text-3xl font-bold items-start mb-2`,
    subtitle: tw`text-base font-normal items-start mb-8 opacity-70`,
    text: tw`antialiased text-tellow-gray-600 text-sm max-w-prose font-normal mb-0 -mt-2 mb-2 mr-6`,
    content: tw`flex flex-row items-start`,
    littleTitle: tw`antialiased text-lg font-semibold tracking-tight`,
    svg: tw`h-10 w-10 fill-none! text-tellow-purple mr-4 rounded-full bg-tellow-purple bg-opacity-10 justify-center items-center p-2`,
    img: tw` hidden md:inline mx-auto mb-0 p-0`,
    imageContainer: tw`flex flex-col justify-center`,
    centerContent: tw`flex flex-col h-auto items-start justify-center`,
    link: tw`antialiased text-xs text-tellow-purple cursor-pointer font-semibold mt-6 mr-6`,
}

const DividedItems = styled.div`
    > * + * {
        border-top: 1px solid ${({ theme }) => theme.color.tellowGray200};
        padding-top: 2rem;
    }
`

const TopHighlights = ({ data }) => (
    <div css={style.wrapper}>
        <div css={style.grid}>
            <div css={style.centerContent}>
                <DividedItems css={style.pointsGrid}>
                    {data.points.map(({ title, body, links, url }) => (
                        <div key={title}>
                            <p css={style.littleTitle}>{title}</p>
                            <p css={style.text}>{body}</p>
                            <a href={url} rel="noopener noreferrer" css={style.link}>
                                {links} <span className="arrow">{'->'}</span>
                            </a>
                        </div>
                    ))}
                </DividedItems>
            </div>
            <div css={style.imageContainer}>
                <img css={style.img} src={data.benefitImage} alt={data.altImage} />
            </div>
        </div>
    </div>
)

export default TopHighlights
