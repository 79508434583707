import React from 'react'

const Bag = () => (
    <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.8001 10.8001V9.0001C10.8001 7.56793 11.369 6.19442 12.3817 5.18172C13.3944 4.16902 14.7679 3.6001 16.2001 3.6001H19.8001C21.2323 3.6001 22.6058 4.16902 23.6185 5.18172C24.6312 6.19442 25.2001 7.56793 25.2001 9.0001V10.8001H28.8001C29.7549 10.8001 30.6705 11.1794 31.3457 11.8545C32.0208 12.5296 32.4001 13.4453 32.4001 14.4001V20.8261C27.7914 22.5338 22.915 23.4055 18.0001 23.4001C13.0852 23.4056 8.20876 22.5339 3.6001 20.8261V14.4001C3.6001 13.4453 3.97938 12.5296 4.65451 11.8545C5.32964 11.1794 6.24532 10.8001 7.2001 10.8001H10.8001ZM14.4001 9.0001C14.4001 8.52271 14.5897 8.06487 14.9273 7.72731C15.2649 7.38974 15.7227 7.2001 16.2001 7.2001H19.8001C20.2775 7.2001 20.7353 7.38974 21.0729 7.72731C21.4105 8.06487 21.6001 8.52271 21.6001 9.0001V10.8001H14.4001V9.0001ZM16.2001 18.0001C16.2001 17.5227 16.3897 17.0649 16.7273 16.7273C17.0649 16.3897 17.5227 16.2001 18.0001 16.2001H18.0181C18.4955 16.2001 18.9533 16.3897 19.2909 16.7273C19.6285 17.0649 19.8181 17.5227 19.8181 18.0001C19.8181 18.4775 19.6285 18.9353 19.2909 19.2729C18.9533 19.6105 18.4955 19.8001 18.0181 19.8001H18.0001C17.5227 19.8001 17.0649 19.6105 16.7273 19.2729C16.3897 18.9353 16.2001 18.4775 16.2001 18.0001Z"
            fill="currentColor"
        />
        <path
            d="M3.6001 24.6455V28.7999C3.6001 29.7547 3.97938 30.6704 4.65451 31.3455C5.32964 32.0206 6.24532 32.3999 7.2001 32.3999H28.8001C29.7549 32.3999 30.6705 32.0206 31.3457 31.3455C32.0208 30.6704 32.4001 29.7547 32.4001 28.7999V24.6455C27.7603 26.2093 22.8963 27.0045 18.0001 26.9999C12.9673 26.9999 8.1235 26.1719 3.6001 24.6455Z"
            fill="currentColor"
        />
    </svg>
)

export default Bag
