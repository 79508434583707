import React from 'react'
import { Layout } from '../../components/layout'
import Meta from '../../components/layout/meta'
import { RelativeContainer, ComponentContainer, ColoredBackgroundWithProps } from '../../components/shared/landingPages/layoutComponents'
import Header from '../../components/shared/landingPages/header'
import HighlightedFunctionalityInkomstenbelasting from '../../components/shared/landingPages/highlightedFunctionalityInkomstenbelasting'
import FeatureLinks from '../../components/shared/landingPages/featureLinks'
import Benefits from '../../components/shared/landingPages/benefits'
import { Accordeon } from '../../components/UI'
import { Title } from '../../components/shared/landingPages/title'

import headerPng from '../../images/inkomstenbelasting/Header.png'
import highlightedFunctionalityImage from '../../images/incometax/First-step-image.png'
import benifitsImage from '../../images/incometax/Benifits-image.png'
import Bag from '../../images/invoicelp/bag.js'
import Credit from '../../images/icons/credit.js'

const headerData = {
    title: `Inkomstenbelasting`,
    text: `Een foutloze inkomstenbelastingaangifte. Maak gebruik van alle aftrekposten, zo houd je onder de streep het maximale over. `,
    headerImage: headerPng,
    textCTA: `Gebruik Tellow gratis`,
}

const highlightedFunctionalityInkomstenbelastingData = {
    Icon: Bag,
    title: `Inkomstenbelasting met Tellow Compleet`,
    firstStepImage: highlightedFunctionalityImage,
    textCTA: `Probeer het zelf`,
    list: [
        ` Inkomstenbelasting én btw-aangiftes gecheckt door jouw boekhouder`,
        ` Met een klik automatisch indienen via de officiële Belastingdienst integratie`,
        `Toch nog vragen? Onbeperkt advies van je boekhouder`,
    ],
}

const benefitsData = {
    points: [
        {
            title: 'Prive en zakelijk geregeld',
            body: 'Met Tellow Compleet wordt zowel zakelijk als privé alles voor je opgesteld en automatisch ingediend. En voor je fiscale partner, wanneer deze in loondienst is. ',
            links: 'Maak je eerste factuur',
            url: `https://app.tellow.nl/registreer#/`,
        },
        {
            title: 'Persoonlijk boekhouder',
            body: 'Mis geen kortingen en aftrekposten en laat je inkomstenbelasting doen door één van onze professionele boekhouders. Je boekhouder staat altijd voor je klaar wanneer je hulp of advies nodig hebt.',
            links: 'Bekijk het overzicht ',
            url: `https://tellow.nl/plan/compleet/`,
        },
        {
            title: 'Verdien je kosten terug',
            body: 'Jouw boekhouder geeft pro actief bespaartips. Zo mis jij geen aftrekposten of besparingen en houd je uiteindelijk het meeste over.',
            links: 'Maak een account',
            url: `https://app.tellow.nl/registreer#/`,
        },
    ],
    benefitImage: benifitsImage,
    altImage: `Benefit Image`,
}

const featureData = [
    {
        Icon: Credit,
        title: `Bankrekening koppelen`,
        text: `Koppel je bankrekening aan Tellow en maak je administratie nog makkelijker. Direct inzicht in al je uitgave en inkomsten.`,
        link: ` Meer over het koppelen van je rekening `,
        path: `koppel-je-rekening`,
    },
    {
        Icon: Credit,
        title: `Bonnen scannen`,
        text: `Scan gemakkelijk al je bonnetjes via de app. Nooit meer bonnetjes bewaren.`,
        link: ` Meer over het scannen van bonnen`,
        path: `bonnen/`,
    },
]

const faq = [
    {
        question: `Waar kan ik meer lezen over Tellow Compleet?`,
        answer: `Dat kan <a href="https://www.tellow.nl/plan/compleet/">hier</a> op deze speciale pagina.`,
    },
    {
        question: `Wanneer moet ik inkomstenbelasting doen als zzp’er?`,
        answer: `Je moet inkomstenbelastingaangifte doen in maart en april 2023. Red je dit niet? Dan kun je gemakkelijk uitstel aanvragen via de website van de Belastingdienst.`,
    },
    {
        question: `Hoe bereken ik mijn inkomstenbelasting?`,
        answer: `Dit is een ingewikkelde berekening. En in principe doet de Belastingdienst dit voor je als je alle gegevens invult. Wil je het toch zelf berekenen dan kun je gebruik maken van een rekenvoorbeeld of stappenplannen die beschikbaar zijn, zoals <a href="https://www.tellow.nl/blog/inkomstenbelasting-aangifte-zzp/">hier</a>.`,
    },
]

const Inkomstenbelasting = () => (
    <Layout>
        <Meta
            path="/functies/inkomstenbelasting"
            title="Inkomstenbelasting doen met Tellow"
            description="Doe gemakkelijk je inkomstenbelasting met Tellow. Laat je inkomstenbelasting controleren door een boekhouder en stuur automatisch door naar de Belastingdienst. "
        />

        {/* header component */}
        <Header data={headerData} smallerTitleSizeOnMobile />

        {/* highlighted functionality inkomstenbelasting component */}
        <ColoredBackgroundWithProps purple>
            <RelativeContainer>
                <ComponentContainer>
                    <HighlightedFunctionalityInkomstenbelasting data={highlightedFunctionalityInkomstenbelastingData} purpleBackground bottomImage />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        {/* banifits component */}
        <RelativeContainer>
            <ComponentContainer>
                <Benefits data={benefitsData} />
            </ComponentContainer>
        </RelativeContainer>

        {/* feature link components  */}
        <RelativeContainer>
            <ComponentContainer>
                <FeatureLinks data={featureData} />
            </ComponentContainer>
        </RelativeContainer>

        {/* faq component */}
        <RelativeContainer>
            <ComponentContainer direction="column" padding={1.5}>
                <Title>Veelgestelde vragen</Title>
                {faq.map(({ question, answer }) => (
                    <Accordeon key={question} question={question}>
                        <div dangerouslySetInnerHTML={{ __html: answer }} />
                    </Accordeon>
                ))}
            </ComponentContainer>
        </RelativeContainer>
    </Layout>
)

export default Inkomstenbelasting
