import React from 'react'
import tw, { styled } from 'twin.macro'

// Tailwind style
const style = {
    wrapper: tw`max-w-7xl w-full pb-4 mx-auto px-4 sm:px-6 lg:px-8 my-4`,
    grid: tw`grid grid-cols-1 md:grid-cols-2 gap-20`,
    divide: tw`flex flex-row items-center mb-2`,
    point: tw`antialiased text-lg font-semibold tracking-tight mb-0`,
    text: tw`antialiased max-w-sm text-tellow-gray-600 text-sm font-normal mb-2 ml-14`,
    svg: tw`h-10 w-10 fill-none! text-tellow-purple mr-4 rounded-full bg-tellow-purple bg-opacity-10 flex justify-center items-center p-2`,
    link: tw`antialiased text-xs text-tellow-purple cursor-pointer font-semibold ml-14`,
}

const StyledDivide = styled.div`
    border-top: 1px solid ${({ theme }) => theme.color.tellowGray200};
    padding-top: 3rem;
`

const FeatureLinks = ({ data }) => (
    <div css={style.wrapper}>
        <StyledDivide css={style.grid}>
            {data.map(({ Icon, title, text, link, path }) => (
                <div key={title}>
                    <div css={style.divide}>
                        <div css={style.svg}>
                            <Icon />
                        </div>
                        <p css={style.point}>{title}</p>
                    </div>
                    <p css={style.text}>{text}</p>
                    <a href={`/functies/${path}`} css={style.link}>
                        {link} <span className="arrow">{'->'}</span>
                    </a>
                </div>
            ))}
        </StyledDivide>
    </div>
)

export default FeatureLinks
