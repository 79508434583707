import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'

const style = {
    grid: tw`grid grid-cols-1 md:grid-cols-2 gap-12`,
    imageContainerBottom: tw`flex flex-col justify-end sm:-mb-12`,
    imageContainer: tw`flex flex-col justify-center`,
    img: tw`md:inline max-w-xs mx-auto my-0 mb-0 p-0 filter drop-shadow-xl`,
    content: tw`flex flex-col h-auto items-start justify-center`,
    bgWhite: tw`bg-tellow-white`,
    bgPurple: tw`bg-tellow-purple bg-opacity-10`,
    svg: tw`h-8 w-8 fill-none! text-tellow-purple mb-3 mr-4 rounded-full justify-center items-center p-1.5`,
    title: tw`antialiased leading-tight tracking-tighter text-2xl font-bold items-start mb-6`,
    textWhite: tw`text-tellow-white`,
    text: tw`antialiased max-w-prose leading-loose text-sm font-normal pt-2 mb-8`,
}

const MetaContainer = styled.div`
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 25px;
`
const MainContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 3rem;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
        gap: 3rem;
    }
`
const HighlightedFunctionalityInkomstenbelasting = ({ data, purpleBackground, bottomImage }) => (
    <MainContainer>
        <div css={bottomImage ? style.imageContainerBottom : style.imageContainer}>
            <img css={style.img} src={data.firstStepImage} alt="Facturen" />
        </div>
        <MetaContainer>
            <div css={style.content}>
                <div css={[purpleBackground ? style.bgWhite : style.bgPurple, style.svg]}>
                    <data.Icon />
                </div>

                <h1 css={[style.title, purpleBackground ? style.textWhite : null]}>{data.title}</h1>
                <div>
                    <ul css={style.text} style={{ listStyleType: 'disc', marginLeft: `17px` }}>
                        {data.list.map((item) => (
                            <li key={item}>{item}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </MetaContainer>
    </MainContainer>
)

export default HighlightedFunctionalityInkomstenbelasting
